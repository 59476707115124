@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.noPadding {
  padding: 0 !important;
}
@keyframes ImgBounce {
  0% {
    transform: rotateZ(360deg);
  }
}
.loadingIcon svg {
  -webkit-animation: ImgBounce 1s infinite;
  animation: ImgBounce 1s infinite;
  width: 20%;
  height: 20%;
}

.react-datepicker-popper {
  z-index: 99999;
  position: relative;
}
